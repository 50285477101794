import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators';
import { StorageService } from 'app/main/module/common/service/storage.service';
import { HttpClient } from '@angular/common/http';
import { Maintenance } from '../maintenance';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class WaterlineService {
table = 'history';
orderBy = 'updatedOn';
ROWS = 10;
private searchSubject: BehaviorSubject<Maintenance[]>;
public searchObservable: Observable<Maintenance[]>;
public searchedText: string = null;
currentPage = 0;
// apiURL = 'https://northamerica-northeast1-sterilwize-dev-5ebe8.cloudfunctions.net';
constructor(private afs: AngularFirestore, private userServiceChild: StorageService,private http: HttpClient) {
    this.table = 'history';
    this.orderBy = 'updatedOn';
}
// httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type': 'application/json'
//   })
// }

// getdataFromMaintenaceHistory(): Observable<Maintenance> {
//   return this.http.post<Maintenance>(this.apiURL + '/getDocuments', JSON.stringify({ "path": "lab/" + this.userServiceChild.getCookie("lab") + "/maintanance/result/history" }), this.httpOptions)
//     .pipe(
//       retry(1),
//       catchError(this.handleError)
//     )
// }


getCollection(ref, queryFn?): Observable<any[]> {
    return this.afs.collection(ref, queryFn).snapshotChanges().pipe(map(actions => {
        return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            const doc = a.payload.doc;
            return { id, ...data as {}, doc };
        });
    }));
}
handleError(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
    // Get client-side error
    errorMessage = error.error.message;
  } else {
    // Get server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
  }
  window.alert(errorMessage);
  return throwError(errorMessage);
}

getSearchQueryWithOutText(): any {
    return this.getCollection(this.getCollectionPath(), ref => ref
        .orderBy(this.orderBy)
        .limit(this.ROWS));
}



getCollectionPath(): string {
    return 'lab/' + this.userServiceChild.getCookie("lab") + "/maintanance/result/history";
}










}
