import { Routes } from '@angular/router';
import { AuthGuard } from './main/module/auth/auth.guard';
import { PatientComponent } from './main/module/sterilwize/patient/list/patient.component';
import { LabelComponent } from './main/module/sterilwize/label/label.component';
import { MaintenanceComponent } from './main/module/auth/maintenance/maintenance.component';
import { AddLoadComponent } from './main/module/sterilwize/load/addload/addload.component';
import { ViewcassettedetailsComponent } from './main/module/sterilwize/cassette/detail/viewcassettedetails.component';
import { ScreeningPatientResultListComponent } from './main/module/screening/patient/results/list/screening-patient-result-list.component';
import { SelfAssessmentResolutionComponent } from './main/module/screening/patient/self-assessment/resolution/self-assessment-resolution.component';
import { SelfAssessmentInitComponent } from './main/module/screening/patient/self-assessment/init/self-assessment-init.component';
import { LoaddetailComponent } from './main/module/sterilwize/load/loaddetail/loaddetail.component';
import { EmaildDetailComponent } from './main/module/sterilwize/email/viewemaildetails/vieweEmailDetails.component'
import { SelfAssessmentQuestionsComponent } from './main/module/screening/patient/self-assessment/questions/self-assessment-questions.component';
import { PiComponent } from './main/module/sterilwize/pi/detail/pi.component';
import { SelfAssessmentQuestionsService } from './main/module/screening/patient/self-assessment/questions/self-assessment-questions.service';
import { InofficeComponent } from './main/module/screening/patient/results/inOffice/inoffice.component';
import { ProfileComponent } from './main/module/sterilwize/profile/profile.component';
import { StaffinitComponent } from './main/module/screening/staff/staffinit/staffinit.component';
import { MaintenanceHistoryComponent } from './main/module/sterilwize/maintenance/maintenance-history/maintenance-history.component';
import { WaterlineTestComponent } from './main/module/sterilwize/maintenance/waterline-test/waterline-test.component';
import { OthermachineComponent } from './main/module/sterilwize/maintenance/othermachine/othermachine.component';

export const APP_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login',
    },
    {
        path: 'patient',
        component: PatientComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'viewcassettedetails',
        component: ViewcassettedetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'checkout',
        redirectTo: 'checkout',
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    {
        path: 'label',
        redirectTo: 'label',
        canActivate: [AuthGuard],
    },
    {
        path: 'alert',
        redirectTo: 'alert',
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        redirectTo: 'login'
    },
    {
        path: 'register',
        redirectTo: 'register'
    },
    {
        path: 'forgot-password',
        redirectTo: 'forgot-password'
    },
    //   {
    //     path: 'inventory',
    //     component: InventoryComponent,
    //     canActivate: [AuthGuard]
    // },
    // {
    //     path: 'viewtoolsdetail/: equipname',
    //     component: ViewtoolsdetailComponent,
    //     canActivate: []
    // },
    {
        path: 'label',
        component: LabelComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'validate',
        component: MaintenanceComponent,

    },
    {
        path: 'addload',
        component: AddLoadComponent,

    },
    {
        path: 'loaddetail',
        component: LoaddetailComponent,

    },
    {
        path: 'ScreeningPatientResultList',
        component: ScreeningPatientResultListComponent,

    },
    {
        path: 'SelfAssessmentInit',
        component: SelfAssessmentInitComponent,
    },
    {
        path: 'screeningResolution',
        component: SelfAssessmentResolutionComponent,
    },
    {
        path: 'Staffinit',
        component: StaffinitComponent,
    },
    {
        path: 'maintenanceHistory',
        component: MaintenanceHistoryComponent,

    },
    {
        path: 'waterlineTest',
        component: WaterlineTestComponent,

    },
    {
        path: 'othermachine',
        component:OthermachineComponent ,

    },
    {
        path: 'covid-assessment',
        component: SelfAssessmentQuestionsComponent,
        resolve: {
            data: SelfAssessmentQuestionsService
        }
    },
    {
        path: 'connect',
        component: PiComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'support',
        loadChildren: './main/module/support/support.module#SupportModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'rcenter',
        loadChildren: './main/module/sterilwize/rcenter/rcenter.module#RcenterModule',
        canActivate: [AuthGuard],
    },
    {
        path: 'screening',
        loadChildren: './main/module/screening/screening.module#ScreeningModule',

    },
    {
        path: 'email',
        loadChildren: './main/module/sterilwize/email/Email.module#EmailModule',

    },
    {
        path: 'cassette',
        loadChildren: './main/module/sterilwize/cassette/cassette.module#CassetteModule',
    },
    {
        path: 'inoffice',
        component: InofficeComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'emaildetail',
        component: EmaildDetailComponent,

    },
    {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuard]
    },
    {
        path: '**',
        redirectTo: 'login'
    },



];
