import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';

// import {MachineStatus} from '../../../constant';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AnalyticsService } from '../home/analytics.service';
import { throwError } from 'rxjs';
import { DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS } from '@angular/material/core';
import * as moment from 'moment';
import { APP_DATE_FORMATS, AppDateAdapter } from './format-datepicker';
import { ApiService } from '../../common/service/api.service';
import { StorageService } from 'app/main/module/common/service/storage.service';
import {ReportService} from "./report.service";
import { DatePipe } from '@angular/common';


declare var google: any;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}


@Component({
    selector: 'alert',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
    providers: [
         DatePipe,
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
    ]
})
export class ReportComponent implements OnInit {
    ReportForm: FormGroup;
    reportId: any;
    loadStatus: boolean;
    // analyticService: any;
    range: Range;
    labs: string[] = []; // ['DownTown'];
    dateRange: any;
    colorGreen = 'green';

    @Input()
    max: 'D' | null;
    today = new Date();

    @Input()
    min: 'D' | null;
    startAt: any;
    onclicktrue = false;
    filename: string;
    myDate: any;
    centerName: any;
    pdfFileName: string;


    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService, public reportService: ReportService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private userService: StorageService,private datePipe: DatePipe
    ) {
        this.today.setDate(this.today.getDate());
        // console.log('TODAY=',this.today.getDate);
        this.myDate = new Date();
        
            this.myDate = (this.datePipe.transform(this.myDate, 'yyyy-MM-dd_hh-mm-ss_a')).trim();
            // console.log('MYDATE=',this.myDate);
        
    }

    reportType = [
        { name: 'Cassette Report', abbrev: 'cassetteReport' },
        { name: 'Sterilizer Load Report', abbrev: 'loadReport' },
        { name: 'Master Report', abbrev: 'masterReport' },
        { name: 'Assign Cassette Report', abbrev: 'assignCassetteReport' },
        { name: 'Alert Report', abbrev: 'alertReport' },
        { name: 'BI Alert Report', abbrev: 'BIAlertReport' },
        { name: 'Availability Report', abbrev: 'availabilityReport' },
        // { name: 'SOD and EOD Report', abbrev: 'sodEodReport' },
        // { name: 'Sterilizer Maintenance Load Report', abbrev: 'sterilizerMaintenanceLoadReport' },
        // { name: 'Other Maintenance Report', abbrev: 'otherMaintenanceReport' },
        // { name: 'Control BI Report', abbrev: 'controlBIReport' },
    ];

    ngOnInit() {

        // this.apiService.getSubColectionList().subscribe(data =>{
        //     console.log(data);
        // })

        // this.apiService.getLabTeamUsers().then(data=>{
        // console.log(data);
        // })

        // this.apiService.getSubColectionList().then(data=>{
        //     console.log(data);
        // })


  // this.apiService.getFileContent().subscribe(data=>{
        // console.log(data);
        // })

        // this.apiService.addAnalytics().then(data =>{
        //     console.log(data);
        // })

        const labName = this.userService.getCookie("lab");
        this.labs.push(labName);
        // this.ReportForm = new FormGroup({
        // labListName: new FormControl()
        // });
        this.ReportForm = this.formBuilder.group({

            labListName: ['', Validators.required],
            from: ['', Validators.required],
            to: ['', Validators.required],
        });
    }

    fromDateVal(value) {
        this.startAt = value;
    }

    reportOnChangeVal(reportId): void {
        this.reportId = reportId;
    }

    updateRange(range: Range) {
        this.range = range;
    }

    downPdf1(fromD, toD, patientId, patientName): void {

        const from = moment(fromD, 'DD/MM/YYYY').format('MM/DD/YYYY');
        const to = moment(toD, 'DD/MM/YYYY').format('MM/DD/YYYY');

        const rangeValue = from + '-' + to;
        this.dateRange = this.getTimestampData(rangeValue);
        console.log(this.dateRange);
        console.log(rangeValue);

        this.loadStatus = true;
        console.log('localStorage.getItem(staticData) = '+localStorage.getItem('staticData'))

        if (localStorage.getItem('staticData') === 'Y') {
            console.log("IF First condition")
            if (this.reportId === 'cassetteReport') {
                window.open(
                    'http://sterilwize.com/reports/CassetteReport.pdf', '_blank');

            } else if (this.reportId === 'loadReport') {
                window.open(
                    'http://sterilwize.com/reports/LoadReport.pdf', '_blank');
            } else if (this.reportId === 'masterReport') {
                window.open(
                    'http://sterilwize.com/reports/DetailedReport.pdf', '_blank');
            } else if (this.reportId === 'assignCassetteReport') {
                window.open(
                    'http://sterilwize.com/reports/AssignedReport.pdf', '_blank');
            } else if (this.reportId === 'BIAlertReport') {
                window.open(
                    'http://sterilwize.com/reports/BIAlertReport.pdf', '_blank');
            } else if (this.reportId === 'availabilityReport') {
                window.open(
                    'http://sterilwize.com/reports/AvailabilityReport.pdf', '_blank');
            }    else if (this.reportId === 'sodEodReport') {
                window.open(
                    'http://sterilwize.com/reports/sodEodReport.pdf', '_blank');
            } else if (this.reportId === 'sterilizerMaintenanceLoadReport') {
                    window.open(
                        'http://sterilwize.com/reports/AvailabilityReport.pdf', '_blank');
            } else if (this.reportId === 'otherMaintenanceReport') {
                        window.open(
                            'http://sterilwize.com/reports/AvailabilityReport.pdf', '_blank');
            } else if (this.reportId === 'controlBIReport') {
                            window.open(
                                'http://sterilwize.com/reports/AvailabilityReport.pdf', '_blank');
            }
            
            else {
                alert('No report Selected');
            }

        }
        if (localStorage.getItem('staticData') === 'N') {
             this.centerName = this.getStringOrDefault(this.userService.getCookie("center"),"");
            console.log(this.centerName);
            this.onclicktrue = true;
            //  this.filename = (this.reportId.trim()+'_'+this.myDate+'.pdf').trim();
            //  console.log(this.filename);
            if (this.reportId == 'cassetteReport') {

                this.pdfFileName = 'CR'
                
                } else if (this.reportId == 'loadReport') {
                
                this.pdfFileName = 'LR'
                
                } else if (this.reportId == 'masterReport') {
                
                this.pdfFileName = 'MR'
                
                } else if (this.reportId == 'assignCassetteReport') {
                
                this.pdfFileName = 'ACR'
                
                } else if (this.reportId == 'alertReport') {
                
                this.pdfFileName = 'AR'
                
                } else if (this.reportId == 'BIAlertReport') {
                
                this.pdfFileName = 'BIAR'
                
                } else if (this.reportId == 'availabilityReport') {
                
                this.pdfFileName = 'AVR'
                
                }  else if (this.reportId == 'sodEodReport') {
                
                    this.pdfFileName = 'SOD'
                } else if (this.reportId == 'sterilizerMaintenanceLoadReport') {
                
                    this.pdfFileName = 'AVR' 
                } else if (this.reportId == 'otherMaintenanceReport') {
                
                    this.pdfFileName = 'AVR'
                } else if (this.reportId == 'controlBIReport') {
                
                    this.pdfFileName = 'AVR'
                }        
                this.filename = ('WEB_'+this.pdfFileName +from + 'to' + to+ '.pdf').trim();
            if ((this.centerName  !== undefined && this.centerName  !== 'undefined') && this.dateRange && this.labs && this.reportId !== undefined ) {
                console.log('CENTERWIZE=',this.centerName,' , this.labs = ',this.labs);
                this.reportService.downloadPdfCenterWize(this.centerName,this.dateRange, this.labs, this.reportId, patientId, patientName, Intl.DateTimeFormat().resolvedOptions().timeZone).subscribe(

                    res => this.extractPdf(res),
                    (error: any) => throwError('Server error'));

            } else if (this.dateRange && this.labs && this.reportId !== undefined ) {
                console.log('NONCENTERWIZE=',this.centerName , 'this.labs = ',this.labs);
                    this.reportService.downloadPdf(this.dateRange, this.labs, this.reportId, patientId, patientName, Intl.DateTimeFormat().resolvedOptions().timeZone).subscribe(
    
                        res => this.extractPdf(res),
                        (error: any) => throwError('Server error'));
    
                }
            
            else {
                this.onclicktrue = false;
                alert('Please Provide Required Data');
                this.loadStatus = false;
            }
        }
    }
    extractPdf(res) {

        // transforme response to blob
        
        if (!res) {
        
        alert('No data available for the selected date range.');
        
        this.loadStatus = false;
        
        return false;
        
        }
       
        
        var blob = new Blob([res], { type: 'application/pdf' });
        
        var anchor = document.createElement("a");
        
        var url = window.URL.createObjectURL(blob);
        
        anchor.download = this.filename;
        
        anchor.href = url;
        
        anchor.click();
        
        // window.open(anchor.href);
        
        this.loadStatus = false;
        
        return false;
        
        // }
        
        }

    // extractPdf(res) {
    //     // transforme response to blob
    //     if(!res){
    //         alert('No data available for the selected date range.');
    //         this.loadStatus = false;
    //         return false;
    //     }
    //     const myBlob: Blob = new Blob([res], { type: 'application/pdf' }); // replace the type by whatever type is your response
    //     const fileURL = URL.createObjectURL(myBlob);
    //     window.open(fileURL);
    //     this.loadStatus = false;
    //     this.onclicktrue = false;
    // }

    // customRange(rangeValue) {
    //     // this.dateRange = this.getTimestampData(rangeValue);
    // }
    /*** code to convert data in timestamp */

    getTimestampData(dateRangeVal: any) {
        const rangeVal = dateRangeVal;
        const rangeDatesArr = rangeVal.split('-');
        const dFrom = rangeDatesArr[0].split('-');
        const dTo = rangeDatesArr[1].split('-');
        return [new Date(dFrom).getTime(), new Date(dTo).getTime()];
    }
    getStringOrDefault(reqkey: any, defaulvalue: any) {

        const rg = defaulvalue
        
        try {
        
        if (reqkey === undefined || reqkey === null) {
        
        return defaulvalue
        
        }
        
        else {
        
        return reqkey
        
        }
        
        }
        
        catch (error) {
        
        rg == defaulvalue
        
        }
        
        }

    /***  end */
    // dateRange(dateRange: any, labs: any, reportId: any) {
    //     throw new Error('Method not implemented.');
    // }


}
