// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//prod1
export const environment = {
    production: false,
    hmr       : false,
    firebaseConfig: {
        apiKey: 'AIzaSyB4r30PJe5-Z1Casd9PuoHQzJBXYRtEaEI',
        authDomain: 'sterilwize-prod.firebaseapp.com',
        databaseURL: 'https://sterilwize-prod.firebaseio.com',
        projectId: 'sterilwize-prod',
        storageBucket: 'sterilwize-prod.appspot.com',
        messagingSenderId: '500483397029',
        appId: 'sterilwize-prod',
        cloudFunctionURL: 'https://northamerica-northeast1-sterilwize-prod.cloudfunctions.net/',
        websiteUrl: 'https://sterilwize-prod.firebaseapp.com/',
        websiteFront:'https://wize.ai/',
    }
};