import { MaintenanceHistoryComponent } from "app/main/module/sterilwize/maintenance/maintenance-history/maintenance-history.component"
import { OthermachineComponent } from "app/main/module/sterilwize/maintenance/othermachine/othermachine.component"

export const allTrue = {
    home:true,
    load:true,
    cassette:true,
    cassettes:true,
    checkout:true,
    unassign:true,
    patient:true,
    report:true,
    email:true,
    labelhistory:true,
    hubWize:true,
    Covid:true,
    'Covid Assessment Result':true,
    'Staff Screening':true,
    setting:true,
    team:true,
    questionconsent:true,
    consent:true,
    question:true,
    patientResolutionSetting:true,
    staffResolutionSetting:true,
    screeningEmailSetting:true,
    inventory:true,
    maintenance:true,
    maintenanceHistory:true,
    waterlineTest:true,
    othermachine:true,
    label:true,
    support:true,
    academy:true,
    'Training Center':true,
    faq:true,
    login:true,
    register:true,
    logs: true,
}

export const covidTrue = {
    home:false,
    load:false,
    cassette:false,
    cassettes:false,
    checkout:false,
    unassign:false,
    patient:false,
    report:false,
    email:false,
    labelhistory:false,
    hubWize:false,
    Covid:true,
    'Covid Assessment Result':true,
    'Staff Screening':true,
    setting:true,
    team:true,
    questionconsent:false,
    consent:true,
    question:true,
    patientResolutionSetting:true,
    staffResolutionSetting:true,
    screeningEmailSetting:true,
    inventory:false,
    label:false,
    support:false,
    academy:false,
    'Training Center':false,
    maintenance:true,
    maintenanceHistory:true,
    waterlineTest:true,
    othermachine:true,
    faq:false,
    login:true,
    register:true,
    logs: false,
}

export const screening = {
    home:false,
    load:false,
    cassette:false,
    cassettes:false,
    checkout:false,
    unassign:false,
    patient:true,
    report:false,
    email:true,
    labelhistory:false,
    hubWize:false,
    Covid:true,
    'Covid Assessment Result':true,
    'Staff Screening':true,
    setting:true,
    team:true,
    questionconsent:true,
    consent:true,
    question:true,
    patientResolutionSetting:true,
    staffResolutionSetting:true,
    screeningEmailSetting:true,
    inventory:false,
    label:false,
    support:false,
    academy:false,
    'Training Center':false,
    maintenance:false,
    maintenanceHistory:false,
    waterlineTest:false,
    othermachine:false,
    faq:false,
    login:true,
    register:true,
    logout:true,
    logs: false,
}

export const resourceCenter = {
    applications:false,
    home:false,
    load:false,
    cassette:false,
    cassettes:false,
    checkout:false,
    unassign:false,
    patient:false,
    report:false,
    email:false,
    labelhistory:false,
    hubWize:false,
    Covid:false,
    'Covid Assessment Result':false,
    'Staff Screening':false,
    setting:false,
    team:false,
    questionconsent:false,
    consent:false,
    question:false,
    patientResolutionSetting:false,
    staffResolutionSetting:false,
    screeningEmailSetting:false,
    inventory:false,
    label:false,
    support:true,
    academy:true,
    'Training Center':false,
    maintenance:false,
    maintenanceHistory:false,
    waterlineTest:false,
    othermachine:false,
    faq:false,
    login:true,
    register:true,
    logout:true,

}
export const idAndUrl = {
    home:['home'],
    load:['load'],
    cassette:['cassette/cassette'],
    cassettes:['cassettes'],
    checkout:['cassette/assign'],
    unassign:['unassign'],
    patient:['patient'],
    report:['report'],
    email:['list'],
    labelhistory:['labelhistory'],
    hubWize:['hubWize'],
    Covid:['Covid'],
    'Covid Assessment Result':['covid'],
    'Staff Screening':['covidstaffresultlist'],
    maintenance:['maintenance'],
    maintenanceHistory : ['maintenanceHistory'],
    othermachine : ['othermachine'],
    waterlineTest :['waterlineTest'],
    team:['team'],
    consent:['consent'],
    question:['question'],
    patientResolutionSetting:['patientResolutionSetting'],
    staffResolutionSetting:['staffResolutionSetting'],
    screeningEmailSetting:['screeningEmailSetting'],
    label:['label'],
    academy:['centers'],
    'Training Center':['tcenter'],
    faq:['faq'],
    login:true,
    register:true,
}
